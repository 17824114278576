@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto";
}

.newcadaster {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 75vh;
  margin-bottom: 140px;
}

.foto {
  margin-top: 50px;
  width: 200px;
}

.newPhoto {
  margin: 20px 0;
  background-color: transparent;
  border: 1px solid blue;
  border-radius: 10px;
  padding: 10px 30px;
  color: blue;
}

.phoneInput {
  margin-top: 12px;
  max-width: 320px;
}

.PhoneInputInput {
  border: none;
  border-bottom: 1px solid black;
  outline: none;
}

.switch {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-family: "Roboto";
  max-width: 320px;
}

.switch label {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.termo {
  margin-top: 18px;
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 320px;
}

.termoHeader {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  font-family: "Roboto";
}

.termoInfo {
  font-size: 0.8em;
  font-family: "Roboto";
  color: #808080;
  opacity: 1;
  display: flex;
  margin-right: 25px;
}

.saveButton {
  padding: 10px 130px;
  background: #317bff 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 8px;
  opacity: 1;
  color: white;
  margin-top: 40px;
}

.saveButton[disabled] {
  background-color: #cccccc;
  color: #666666;
}

/* camera */

.cam {
  position: absolute;
  background-color: black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 80vh;
  padding: 50px;
}

.overlay-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  /* left: 0;
  right: 0;
  bottom: 0;  */
}

.buttons {
  max-width: 600px;
  height: 10%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  /* margin-left: auto;
  margin-right: auto; */
  z-index: 1000;
}

.buttons2 {
  max-width: 90%;
  height: 10%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 40px; */
  margin-bottom: 100px;
  /* margin-left: auto;
  margin-right: auto; */
  z-index: 1000;

}
.capture {
  border: 3px solid #f1f1f1;
  background-color: transparent;
  border-radius: 50%;
  /* padding: 30px; */
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  /* margin-left: 30px; */
}
.captureButton {
  background-color: #f1f1f1;
  border-radius: 50%;
  /* padding: 30px; */
  width: 65px;
  height: 65px;

  /* margin-left: 30px; */
}
.close {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 2em;
}

.camod {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 2em;
}

.cardFoto {
  width: 150px;
  height: 150px;
  border-radius: 200px;
  border: 2px solid #e9e9e980;
}

.cond {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerCond > h1 {
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-size: 1.2em;
  margin-top: 16px;
  color: #828282;
}

.headerCond > p {
  margin-top: 16px;
}

.headerCond > span {
  display: flex;
  align-self: center;
  justify-content: center;
  font-size: 2em;
  color: rgb(0, 126, 253);
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 16px;
}

.info > p {
  font-family: "Roboto", sans-serif;
  color: #828282;
  margin: 10px;
}

.button {
  margin: 25px;
  border: 1px solid rgb(0, 110, 255);
  border-radius: 10px;
  padding: 10px 20px;
  text-decoration: none;
  color: rgb(0, 126, 253);
  font-weight: bold;
}
.button2 {
  margin: 25px;
  border: 1px solid rgb(0, 110, 255);
  border-radius: 50px;
  padding: 10px 20px;
  text-decoration: none;
  background-color: rgb(0, 110, 255);
  color: #fff;
  font-weight: bold;
  width: 200px;
}

.dicas > p {
  font-family: "Roboto", sans-serif;
  margin: 0 0 5px 10px;
}

.dicas > img {
  width: 100%;
  margin-bottom: 100px;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

.conviteWeb {
  height: 100vh;
}

.convite {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-family: "Roboto";
}

.convite img {
  width: 30%;
  margin: 0px 0px 60px 0;
}

.text-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.text-container div {
  padding: 10px;
  color: rgb(100, 100, 100);
}

.convite button {
  background-color: rgb(0, 110, 255);
  border: none;
  border-radius: 8px;
  margin-top: 20px;
  padding: 15px 30px;
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.autenticacao {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
}

.autenticacao h1 {
  font-size: 20px;
  padding-bottom: 10px;
}

.detalhes {
  font-family: "Roboto";
  font-weight: lighter;
}

/* INPUT RANGE */

.input-range {
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.input-range-name {
  color: #424242;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px;
  font-family: "Roboto";
}

.input-range-middle {
  color: #546e7a;
  font-size: 0.75em;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px;
}

.input-range-container {
  width: 100%;
  height: 70px;
  background-color: #ccc;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
}

.input-range-container-trigger {
  width: 100%;
  height: 70px;
  background-color: #28a745;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
}
.input-range-element {
  position: absolute;
  width: 65px;
  height: 65px;
  border-radius: 50px;
  background-color: #28a745;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 4;
  left: 20px;
}

.input-range-lock {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  padding-right: 20px;
  width: 89%;
}

.input-range-lottie {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;
  z-index: 2;
}

.input-range-lottie-no-active {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 65%;
  z-index: 2;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .loadingGps {
    height: 100vh;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: '#000';
  } */

.gps-container {
  position: relative;
  top: 175px;
  right: 0;
  bottom: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  height: "100vh";
}

/* .loadingGps {
    height: 100vh;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: '#000';
  
  } */

.card-information-header {
  color: "#fff";
  font-family: "Roboto";
}
