.realizado{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: 'center';
    text-align: center;
    
}

.realizado img{
    width: 30%;
}

.realizado p{
    margin-top: 30px;
    padding: 0 30px 0 30px;
    font-family: 'Roboto';
    display: flex;
    flex-wrap: wrap;
}