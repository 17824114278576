@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

.cond {
  display: flex;
  flex-direction: column;
}

.headerCond {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.headerCond > div {
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-size: 1.2em;
  margin-top: 30px;
  color: #828282;
}

.headerCond > img {
  margin-top: 20px;
  width: 30%;
}

.conviteAprovado {
  font-family: "Roboto", sans-serif;
  color: #828282;
  margin: 10px;
}

.info > div {
  font-family: "Roboto", sans-serif;
  color: #828282;
  margin: 10px;
}

.infoButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  background-color: transparent;
  margin: 25px;
  border: 1px solid rgb(0, 110, 255);
  border-radius: 10px;
  padding: 15px 20px;
  color: rgb(0, 126, 253);
  font-weight: bold;
}

.dicas > p {
  font-family: "Roboto", sans-serif;
  margin: 0 0 5px 10px;
}

.dicas > img {
  width: 100%;
}

.qrimage {
  /* width: 50%; */
  border-radius: 15px;
}

.qrinfo {
  font-family: "Roboto";
  color: #888888;
}

.instrucao {
  /* margin-top: 50px; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 30px;
  background: "red";
}

.instrucao > img {
  width: 30%;
}

.instrucao .info {
  font-size: 13px;
}

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.realizado {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin-top: 20px;
  /* padding: 10px;     */
  /* background-color: aquamarine; */
}

#qr-code-reader video {
  width: 100%;
  height: 100%;
}
