.linkbutton {
  border: 1px solid #317bff;
  border-radius: 10px;
  background-color: white;
  width: 300px;
  padding: 18px 0px;
  margin-top: 14px;
  color: #317bff;
  font-size: 18px;
  font-weight: bold;
}

.nameCondominium {
  margin: 0 35px;
  color: #888888;
  margin-bottom: 40px;
}

.title {
  margin-top: 25px;
  font-weight: normal;
  color: #828282;
  font-size: 20px;
}

.cond {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
}
