

.input-data{
    align-items: center;
    text-align: left;   
    width: 100%; 
    margin-bottom: 25px;
    max-width: 320px;
}

.input-data input{
    width: 100%;
    border: none;
    border-bottom: 1px solid #9f9f9f;
    padding: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    outline: none;
    font-size: larger;
}


.input-data label{
    /* position: absolute; */
    color: lightgray;
    /* transition: .3s;     */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
}

.input-data input[type="text"][disabled] {
    color: rgb(154, 154, 154);
 }