.presencial{
    height: 100vh;
}

.presencialHeader{
 
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #828282;
    border-radius: 10px;
    margin: 20px 30px;
    padding: 10px 30px;
    text-align: center;
    font-family: 'Roboto';
    color: #828282;
}

.presencialHeader img{
    width: 15%;
    margin-bottom: 10px
}