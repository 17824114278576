.loadingGps {
  height: 100vh;
  width: '100%';
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: '#000';

}

.card-information-header{
  color: '#fff';
  font-family: 'Roboto';
}