.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid rgb(161, 160, 160);
  padding: 10px 0px 10px 20px;
  position: fixed;
  bottom: 0;
  height: 60px;
  background: #fff 0% 0% no-repeat padding-box;
}

/* .infopriv{
    color: #828282;
    font-family: 'Roboto';
    font-weight: lighter;
    
} */
.footer a{
    text-decoration: none;
    flex-wrap: wrap;
    text-align: center;
    font-weight: bolder;
    font: normal normal normal 15px;
    color: #828282; 
    font-family: 'Roboto';
}
