

.textVehicle {
    font-family: 'Roboto';
    font-weight: 'bolder';
    padding-left: '5px';
    color:'#dc3545';
}



.text {
    color:'#424242';
}


