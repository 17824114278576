.qr-reader {
  width: 430px;
  height: 430px;
  margin: 0 auto;
  position: relative;
  border-radius: 15px;
}

.qr-reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
}
