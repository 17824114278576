.container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerCond > h1{
    font-family: 'Roboto', sans-serif;
    text-align: center;
    font-size: 1.2em;
    margin-top: 16px;
    color: #828282;
}

.headerCond > p{
    margin-top: 16px;
}

.headerCond > span{
    display: flex;
    align-self: center;
    justify-content: center;
    font-size: 2em;
    color: rgb(0, 126, 253);
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 16px;
}

.info > p{
    font-family: 'Roboto', sans-serif;
    color: #828282;
    margin: 10px;
}

.buttonInfo{
    background-color: transparent;
    margin: 25px;
    border: 1px solid rgb(0, 110, 255);
    border-radius: 7px;
    padding: 10px 20px;
    text-decoration: none;
    color: rgb(0, 126, 253);
    font-weight: bold;
    width: 100px;
    font-family: "Roboto";
}

.dicas > p{
    font-family: 'Roboto', sans-serif;
    margin-left: -5px;
    margin-bottom: -5px;
    color: #828282;
}

.dicas > img{
    width: 100%;

}

.textDestaque {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.706);
    text-transform: capitalize;
}

.headerInfo{
    margin-top: 80px;
    margin-bottom: 30px;
    text-align: center;
}

.headerInfo > p {
    font-family: "Roboto";
    color: #424242;
}