@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

.conviteWeb{
  height: 100vh;
}

.convite{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    font-family: 'Roboto';
}

.convite img{
    width: 30%;
    margin: 0px 0px 60px 0;
}

.text-container{
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.text-container div{
    padding: 10px;
    color: rgb(100, 100, 100);
}


.convite button{
    background-color: #317bff;
    border: none;
    border-radius: 8px;
    margin-top: 20px;
    padding: 15px 30px;
    text-decoration: none;
    color: white;
    font-weight: bold;
}

.autenticacao {
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
}

.autenticacao h1{
    font-size: 20px;
    padding-bottom: 10px;
}



.detalhes{
    font-family: 'Roboto';
    font-weight: lighter;
}



/* INPUT RANGE */

.input-range {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .input-range-name {
    color: #424242;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 5px;
    font-family: 'Roboto';

  }
  
  .input-range-middle {
    color: #546e7a;
    font-size: 0.75em;
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px;
  }
  
  .input-range-container {
    width: 100%;
    height: 70px;
    background-color: #ccc;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px;

  }
  
  .input-range-container-trigger {
    width: 100%;
    height: 70px;
    background-color: #28A745;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px;

  }
  .input-range-element {
    position: absolute;
    width: 65px;
    height: 65px;
    border-radius: 50px;
    background-color: #28A745;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 4;
    left: 20px;
  }
  
  .input-range-lock {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    padding-right: 20px;
    width: 89%;
  }
  
  .input-range-lottie {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 50%;
    z-index: 2;
  }
  
  .input-range-lottie-no-active {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 65%;
    z-index: 2;
  }


  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
 

  .gps-container{
    position: relative;
    top: 175px;
    right: 0;
    bottom: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }